import React, { useState } from 'react'
import './navbar.css'
import { NavLink, useNavigate } from 'react-router-dom'
import logorhp from '../../assets/logorhp.svg'
import {RxHamburgerMenu} from 'react-icons/rx'
import NavDropdown from './NavDropdown';
import NavDropdown1 from './NavDropdown1';



const Navbar = () => {
    let [open,setOpen]=useState(false);


  return (
    <> 
    <div className='nav'>
      <div className='nav__content Container'>
      <div className='nav__logo'>
        <a href="/"><img src={logorhp} alt="logo RH PRO PLUS"  /></a>
      </div>
      
      <div onClick={()=>setOpen(!open)} className='burger'>
      <span className='burger1'>
        <RxHamburgerMenu/>
      </span>
      </div>

      <ul className={`open ${open ? 'open1 ':'open3'}`}>
        <NavLink to='/'>
          <li className='nav__links' >
            <a className='nav__link ' >Accueil</a>
          </li>
        </NavLink>
        <NavLink to='/Qui_sommes_nous ?'>
          <li className='nav__links' >
            <a className='nav__link ' >Qui sommes nous ?</a>
          </li>
        </NavLink>
        <NavDropdown />
        <NavLink to="/Offres_d'emplois">
          <li className='nav__links' >
            <a className='nav__link ' >Offres d'emplois</a>
          </li>
        </NavLink>
        <NavDropdown1 />
        <div className="contact__button">
            <NavLink to='/Contact'>
            <button>Contacter nous</button>
            </NavLink>
        </div>
      </ul>
      </div>
    </div>
    </>
  )
}

export default Navbar