import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import Button from 'react-bootstrap/Button';

const ExportCSV = ({ csvData, fileName }) => {
  const formatDataForCSV = () => {
    // Assurez-vous que csvData est un tableau non vide
    if (!csvData || csvData.length === 0) {
      return [];
    }
  
    // Obtenez les colonnes à partir de la première ligne
    const columns = Object.keys(csvData[0]);
  
    // Construisez le tableau résultant pour CSVLink
    const formattedData = [];
  
    // Ajoutez chaque ligne de données au tableau résultant
    formattedData.push(columns); // Ajoutez les colonnes en tant que première ligne
  
    csvData.forEach(row => {
      const rowData = columns.map(column => row[column]);
      formattedData.push(rowData);
    });
  
    return formattedData;
  };
  
  

  return (
    <Button variant="warning">
      <CSVLink data={formatDataForCSV()} filename={fileName}>
        Export
      </CSVLink>
    </Button>
  );
};

export default ExportCSV;


