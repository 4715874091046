import { BrowserRouter , Route, Navigate, Routes } from 'react-router-dom';
import './index.css';
import Home from './components/home/Home';
import Contact from './components/contact/Contact';
import About from './components/about/About';
import Offres from './components/offres/Offres';
import Offre from './components/offres/Offre';
import Recrutement2 from './components/offres/Recrutement2';
import Recrutement from './components/recrutement/Recrutement';
import CandidatSpontane from './components/recrutement/CandidatSpontane';
import Admin from './components/Admin/Admin';
import AdminHome from './components/Admin/AdminHome';
import Login from './components/Admin/Login';
import AdminOffers from './components/Admin/adminPages/AdminOffers'
import AdminCondidats from './components/Admin/adminPages/AdminCondidats'
import AdminPartenaires from './components/Admin/adminPages/AdminPartenaires'
import Candidat from './components/Admin/adminPages/Candidat';
import AdminAddOffre from './components/Admin/adminPages/AdminAddOffre';
import AdminUpdateOffre from './components/Admin/adminPages/AdminUpdateOffre';
import AdminCondidats2 from './components/Admin/adminPages/AdminCondidats2';
import Service1 from './components/services/Service1';
import Service2 from './components/services/Service2';
import Service3 from './components/services/Service3';
import Service4 from './components/services/Service4';
import Service5 from './components/services/Service5';
import Service6 from './components/services/Service6';
import Service7 from './components/services/Service7';
import AdminCondidatsSp from './components/Admin/adminPages/AdminCondidatsSp';
import { useState } from 'react';
import CandidatSp from './components/Admin/adminPages/CandidatSp';
import ResetPassword from './components/Admin/ResetPassword';
import Forgotpass from './components/Admin/Frogotpass';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('authToken') !== null);

  return (
    <BrowserRouter>
      <Routes>
        <Route index path='/' element={<Home/>} />
        <Route index path='/Admin' element={<Admin/>} />
        <Route index path='/Contact' element={<Contact/>} />
        <Route index path='/Qui_sommes_nous?' element={<About/>} />
        <Route index path="/Offres_d'emplois" element={<Offres/>} />
        <Route index path="/Offre_d'emploi/:reference" element={<Offre/>} />
        <Route index path="/Recrutement" element={<Recrutement/>} />
        <Route index path="/Candidature_spontanée" element={<CandidatSpontane/>} />
        <Route index path="/Postuler/:poste/:ref" element={<Recrutement2/>} />
        <Route index path="/Conseil_et_accompagnement_RH" element={<Service1/>} />
        <Route index path="/Mise_en_place_et/ou_mise_à_jour_des_outils_RH" element={<Service2/>} />
        <Route index path="/Formation_et_amélioration_de_l’employabilité" element={<Service3/>} />
        <Route index path="/Mise_à_disposition_du_personnel" element={<Service4/>} />
        <Route index path="/Digitalisation_des_processus_RH" element={<Service5/>} />
        <Route index path="/Externalisation" element={<Service6/>} />
        <Route index path="/Service_recrutement" element={<Service7/>} />
        <Route index path='/login' element={isLoggedIn ? <Navigate to='/AdminHome' /> : <Login setIsLoggedIn={setIsLoggedIn} />} />
        <Route index path="/Changer-motpass" element={<ResetPassword/>} />
        <Route index path="/motdepass-oublie" element={<Forgotpass/>} />
        <Route index path='/AdminHome' element={isLoggedIn ? <AdminHome setIsLoggedIn={setIsLoggedIn} /> : <Navigate to='/login' />} />
        <Route index path='/AdminOffers' element={isLoggedIn ? <AdminOffers setIsLoggedIn={setIsLoggedIn} /> : <Navigate to='/login' />} />
        <Route index path='/AdminCondidats' element={isLoggedIn ? <AdminCondidats setIsLoggedIn={setIsLoggedIn} /> : <Navigate to='/login' />} />
        <Route index path='/AdminCondidatsSpontane' element={isLoggedIn ? <AdminCondidatsSp setIsLoggedIn={setIsLoggedIn} /> : <Navigate to='/login' />} />
        <Route index path='/Candidature' element={isLoggedIn ? <AdminCondidats2 setIsLoggedIn={setIsLoggedIn} /> : <Navigate to='/login' />} />
        <Route index path='/AdminPartenaires' element={isLoggedIn ? <AdminPartenaires setIsLoggedIn={setIsLoggedIn} /> : <Navigate to='/login' />} />
        <Route index path='/candidat' element={isLoggedIn ? <Candidat setIsLoggedIn={setIsLoggedIn} /> : <Navigate to='/login' />} />
        <Route index path='/candidatSp' element={isLoggedIn ? <CandidatSp setIsLoggedIn={setIsLoggedIn} /> : <Navigate to='/login' />} />
        <Route index path='/AddOffre' element={isLoggedIn ? <AdminAddOffre setIsLoggedIn={setIsLoggedIn} /> : <Navigate to='/login' />} />
        <Route index path='/ModifierOffre' element={isLoggedIn ? <AdminUpdateOffre setIsLoggedIn={setIsLoggedIn} /> : <Navigate to='/login' />} />
      </Routes>

    </BrowserRouter>
  );
}

export default App;
