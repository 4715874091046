import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import AdminSideBar from '../AdminSideBar'
import './adminOffres.css'

const AdminAddOffre = ({ setIsLoggedIn }) => {
    const [titre, setTitre] = useState('');
    const [reference, setReference] = useState('');
    const [description, setDescription] = useState('');
    const [type_contract, setType_contract] = useState('');
    const [lieu, setLieu] = useState('');
    const [date, setDate] = useState('');

    const VilleMaroc = [
      "Casablanca",
      "Rabat",
      "Fès",
      "Marrakech",
      "Tanger",
      "Agadir",
      "Meknès",
      "Oujda",
      "Kenitra",
      "Tétouan",
      "Salé",
      "Nador",
      "Mohammedia",
      "Taza",
      "Khémisset",
      "Béni Mellal",
      "Essaouira",
      "El Jadida",
      "Tétouan",
      "Safi",
      "Ouarzazate",
      "El Kelaa des Sraghna",
      "Settat",
      "Berrechid",
      "Al Hoceima",
      "Khouribga",
      "Inezgane",
      "Skhirat",
      "Guelmim",
      "Larache",
      "Khenifra",
      "Berkane",
      "Taourirt",
      "Tiznit",
      "Témara",
      "Taounate",
      "Sidi Slimane",
      "Sidi Kacem",
      "Souk El Arbaa",
      "Taourirt",
      "Sidi Qacem",
      "Youssoufia",
      "Safi",
      "Tan-Tan",
      "Taroudant",
      "Tétouan",
      "Ouazzane",
      "Midelt",
      "Ksar El Kebir",
      "M'diq",
      "Fquih Ben Salah",
      "Azrou",
      "Fnideq",
      "Dakhla",
      "Sefrou",
      "Bouznika",
      "Ben Guerir",
      "Bouskoura",
      "Martil",
      "Tinghir",
      "Sidi Yahia El Gharb",
      "Zagora",
    ];

  
    axios.defaults.withCredentials = true;
    const logout = () => {
      // Clear localStorage and set isLoggedIn to false
      localStorage.removeItem('authToken');
      localStorage.removeItem('expiration');
      localStorage.removeItem('username');
      setIsLoggedIn(false);
  };
    

//Ajouter un offre
const handleSubmit = async (e) => {
  e.preventDefault();
  const response = await axios.post('https://rhpro-plus.com/phpServer/addOffre.php', {
    titre,
    reference,
    description,
    type_contract,
    lieu,
    date
  });
  setTitre('');
  setReference('');
  setDescription('');
  setType_contract('');
  setLieu('');
  setDate('');


    // Handle the response as needed
    console.log(response.data);
  
};
    const handleSelectChange = (event) => {
      setType_contract(event.target.value);
    };
    const handleSelectChange2 = (event) => {
      setLieu(event.target.value);
    };
    
  return (
    <>
        <div className='admin__home-cont'>
        <AdminSideBar/>
        <main className="admin__home">
            <div className="admin__nav">
                <button className='deconnexion' onClick={logout}>Déconnexion</button>
            </div>
            <div className="AddOffre">
                <form className="AddOffre-form" onSubmit={handleSubmit}>
                    <div className="off__title">Titre de l'offre</div>
                    <div className="off__input">
                        <input type="text"  name="titre" id="titre" value={titre} onChange={(e) => {setTitre(e.target.value)}} placeholder="Titre de l'offre" required/>
                    </div>
                    <div className="off__title">Reference de l'offre</div>
                    <div className="off__input">
                        <input type="text"  name="reference" id="reference" value={reference} onChange={(e) => {setReference(e.target.value)}} placeholder="Reference de l'offre" required/>
                    </div>
                    <div className="off__title">Type de contrat</div>
                    <select name="type_contract" id="type_contract" value={type_contract} onChange={handleSelectChange}>
                      <option value="">Tous les types</option>
                      <option value="CDI">CDI</option>
                      <option value="CDD">CDD</option>
                      <option value="Contrat de projet">Contrat de projet</option>
                      <option value="Stage pré embauche">Stage pré embauche</option>
                      <option value="Stage">Stage</option>
                    </select>
                    
                    <div className="off__title">Lieu</div>
                    <select name="lieu" id="lieu" value={lieu} onChange={handleSelectChange2}>
                    <option value="">Tous les lieux</option>
                    {VilleMaroc.map((ville)=>{
                      return(
                        <option  value={ville}>{ville}</option>
                      )
                    })
                    }
                    </select>
                    <div className="off__title">Date</div>
                    <div className="off__input">
                        <input type="date" name="date" id="date" value={date} onChange={(e) => {setDate(e.target.value)}}  required/>
                    </div>
                    <div className="off__title">Description</div>
                    <div className="off__input">
                        <textarea type="text" name="description" id="description" value={description} onChange={(e) => {setDescription(e.target.value)}} placeholder="Description" required/>
                    </div>
                    <div className="submit__offre">
                        <button type='submit'>Ajouter</button>
                    </div>
                </form>
            </div>
        </main>
        </div>
    </>
  )
}

export default AdminAddOffre