import React from 'react'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'
import './services.css'
import cover6 from '../../assets/sevicesBanner/cover6.jpg'
import { BsCheckCircleFill } from 'react-icons/bs';
import { IconContext } from "react-icons";
import BackToTopButton from '../../BackToTopButton'

const Service6 = () => {
  return (
    <>
    <Navbar />
    <div className="page__title">
        <div className="page__title-bg">Nos services</div>
    </div>
    <div className="serv__page Container">
        <div className="serv__tit">
            <div className="serv__tit1">Externalisation</div>
            <div className="serv__tit2">
                <div className="serv__tit21" style={{backgroundImage: `url(${cover6})`}}>
                    <div className="service__card__bg21">
                        <div className="service__slogan">
                            <div><span className='human'>HUMAN</span><span className='first'> FIRST</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="serv__desc">
            <div className="desc-det">
                <div>
                    <IconContext.Provider value={{ className: 'service-icons' }}>
                        <BsCheckCircleFill />
                    </IconContext.Provider>
                </div>
            <div className="desc-det-txt">Pour vous recentrer sur le cœur de votre métier et d’activité, vous pouvez nous sous-traiter soit la totalité de la fonction ressources humaines ou une partie, en l’occurrence : la paie, la gestion des conflits, les déclarations sociales et fiscales, la gestion des dossiers administratifs, l’établissement d’un journal interne…</div>
            </div>
            <div className="desc-det">
                <div>
                    <IconContext.Provider value={{ className: 'service-icons' }}>
                        <BsCheckCircleFill />
                    </IconContext.Provider>
                </div>
            <div className="desc-det-txt">Par ailleurs, nous pouvons prendre en charge vos préoccupations liées aux travaux d’étude, d’ingénierie, d’assistance technique . </div>
            </div>
            <div className="desc-det">
                <div>
                    <IconContext.Provider value={{ className: 'service-icons' }}>
                        <BsCheckCircleFill />
                    </IconContext.Provider>
                </div>
            <div className="desc-det-txt">Enfin, nous pouvons mettre à votre disposition un DRH/RRH à temps partiel.</div>
            </div>
            
        </div>
    </div>
    <BackToTopButton />
    <Footer />
    </>
  )
}

export default Service6