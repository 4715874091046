import React from 'react'
import PdfViewer from './PdfViewer';

const Admin = () => {
    const fileId = 4; 
    
  return (
    <div>
      <PdfViewer fileId={fileId} />
    </div>
  )
}

export default Admin