import axios from 'axios'
import React, { useEffect, useState } from 'react'
import AdminSideBar from '../AdminSideBar'

const AdminPartenaires = ({ setIsLoggedIn }) => {

    axios.defaults.withCredentials = true;
  
    const logout = () => {
      // Clear localStorage and set isLoggedIn to false
      localStorage.removeItem('authToken');
      localStorage.removeItem('expiration');
      localStorage.removeItem('username');
      setIsLoggedIn(false);
  };
//upload images api
      const [file, setFile] = useState(null);
    
      const handleFileChange = (e) => {
        setFile(e.target.files[0]);
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('file', file);
    
        try {
            await axios.post('https://rhpro-plus.com/phpServer/addPartenaire.php', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });
            alert('File uploaded successfully!');
            window.location.reload(true);
          } catch (error) {
            console.error(error);
            alert('Error uploading file!');
          }
        };
        
//get images list
const [images, setImages] = useState([]);

  useEffect(() => {
    fetchImages();
  }, []);

  const fetchImages = async () => {
    try {
      const response = await axios.get('https://rhpro-plus.com/phpServer/partenaire.php');
      setImages(response.data);
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };
  const handleDelete = async (id) => {
    try {
        const response = await axios.delete(`https://rhpro-plus.com/phpServer/deletePartenaire.php?id=${id}`);
        window.location.reload(true);
    } catch (error) {
        console.error('Error deleting item:', error);
    }
};
    
  return (
    <> 
        <div className='admin__home-cont'>
        <AdminSideBar/>
        <main className="admin__home">
            <div className="admin__nav">
                <button className='deconnexion' onClick={logout}>Déconnexion</button>
            </div>
            <div className="partenaires ">
                <h3 className="partenaires__title">Ajouter un partenaire</h3>
                <div>
                <form onSubmit={handleSubmit} className="add__partenaires">
                    <input type="file" id="file-input" class="file-input" name="file" onChange={handleFileChange} />
                    <div><label htmlFor="file-input" class="file-input-label ">choisir un fichier</label></div>
                    {file && <div className="image-name-feedback">{file.name}</div>}
                    <button type="submit" className='file-input-label'>Ajouter</button>
                </form>
                </div>
                <h3 className="partenaires__title">Liste des partenaires</h3>
                <div className="liste__partenaires partenaire__cont">
                    {images.map((image) => (
                        <div key={image.id} >
                            <h3>{image.imagName}</h3>
                            <img
                                src={`data:image/jpeg;base64,${image.image}`}
                                alt={image.imageName}
                                className='partenaire__img'
                            />
                            <button className='del-candidat' onClick={() => handleDelete(image.id)}>Supprimer</button>
                        </div>
                    ))}
                </div>
            </div>

        </main>
        </div>
    </>
  )
}

export default AdminPartenaires