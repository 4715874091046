import React, { useEffect, useState } from 'react'
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import './offres.css'
import { Link, useLocation, useParams } from 'react-router-dom';
import BackToTopButton from '../../BackToTopButton'

const Offre = () => {

    const {reference} = useParams();

    const [offer, setOffer] = useState({});
  
    const fetchData = async () => {
      try {
        const response = await fetch(`https://rhpro-plus.com/phpServer/getOfferByRef.php?reference=${reference}`);
        const data = await response.json();
        setOffer(data[0]);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    useEffect(() => {
      // Function to fetch data from the backend
      fetchData();
    }, []);

  
    

  return (
    <>
    <Navbar/>
    <div className="Container">
        <div className="off1__title">{offer.titre}</div>
        <div className="off__det">
            <div className='det_title-cont'>
            <div>
                <h1 className='det_title'>Lieu</h1>
                <p>{offer.lieu}</p>
            </div>
            <div>
                <h1 className='det_title'>Type de contrat</h1>
                <p>{offer.type_contract}</p>
            </div>
            </div>
            <div>
                <Link to={`/Postuler/${offer.titre}/${offer.reference}`}> 
                    <button className='det__button'>Postuler</button>
                </Link>
            </div>
        </div>
        <div className="off__descr">
            <h1 className='det_title'>Description</h1>
            <pre >{offer.description}</pre>
        </div>
    </div> 
    <BackToTopButton />  
    <Footer/>
    </>
  )

}

export default Offre