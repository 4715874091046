import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './NavDropdown.css'; 

const NavDropdown1 = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleToggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleMenuMouseLeave = () => {
    setIsDropdownOpen(false);
  };

  return (
    <div className="nav-dropdown " >
      <li className="dropdown-toggle nav__links" onClick={handleToggleDropdown}>
        <a className='nav__link ' >Recrutement</a>
      </li>
      {isDropdownOpen && (
        <div className="dropdown-menu" onMouseLeave={handleMenuMouseLeave}>
          
            <Link to='/Recrutement' ><div className="dropdown-item">Recrutement</div></Link>
            <Link to='/Candidature_spontanée' ><div className="dropdown-item">Candidatures spontanées</div></Link>
        </div>

        
      )}

    </div>
  );
};

export default NavDropdown1;