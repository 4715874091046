import React from 'react'
import './admin.css'
import logorhp from '../../assets/logorhp.svg'
import { NavLink } from 'react-router-dom'

const AdminSideBar = () => {
  return (
    <aside>
    <div className="sidebar__logo">
        <img src={logorhp} alt="RH PRO PLUS"  />
    </div>
    <div className="sidebar__links">
        <ul>
            <NavLink to='/AdminOffers'>
                <li className='sidelinks'>Offres</li>
            </NavLink>
            <NavLink to='/AdminCondidats'>
                <li className='sidelinks'>Condidats</li>
            </NavLink>
            <NavLink to='/AdminCondidatsSpontane'>
                <li className='sidelinks'>Condidats<br />Spontané</li>
            </NavLink>
            <NavLink to='/AdminPartenaires'>
                <li className='sidelinks'>Partenaires</li>
            </NavLink>
        </ul>
    </div>
    </aside>
  )
}

export default AdminSideBar