import React from 'react'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'
import './services.css'
import cover5 from '../../assets/sevicesBanner/cover5.jpg'
import { BsCheckCircleFill } from 'react-icons/bs';
import { IconContext } from "react-icons";
import BackToTopButton from '../../BackToTopButton'

const Service5 = () => {
  return (
    <>
    <Navbar />
    <div className="page__title">
        <div className="page__title-bg">Nos services</div>
    </div>
    <div className="serv__page Container">
        <div className="serv__tit">
            <div className="serv__tit1">Digitalisation des processus RH</div>
            <div className="serv__tit2">
                <div className="serv__tit21" style={{backgroundImage: `url(${cover5})`}}>
                    <div className="service__card__bg21">
                        <div className="service__slogan">
                            <div><span className='human'>HUMAN</span><span className='first'> FIRST</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="serv__desc">
            <div className="desc-det">
                <div>
                    <IconContext.Provider value={{ className: 'service-icons' }}>
                        <BsCheckCircleFill />
                    </IconContext.Provider>
                </div>
            <div className="desc-det-txt">Nous vous accompagnons pour fiabiliser vos processus RH et optimiser votre personnel et ce à travers la dématérialisation des informations et des données.</div>
            </div>
            <div className="desc-det">
                <div>
                    <IconContext.Provider value={{ className: 'service-icons' }}>
                        <BsCheckCircleFill />
                    </IconContext.Provider>
                </div>
            <div className="desc-det-txt">La digitalisation vous permettra aussi de diminuer vos échanges administratifs et votre consommation de papier.  </div>
            </div>
            
        </div>
    </div>
    <BackToTopButton />
    <Footer />
    </>
  )
}

export default Service5