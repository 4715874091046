import React from 'react'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'
import './services.css'
import cover1 from '../../assets/sevicesBanner/cover1.jpg'
import { BsCheckCircleFill } from 'react-icons/bs';
import { IconContext } from "react-icons";
import BackToTopButton from '../../BackToTopButton'


const Service1 = () => {
  return (
    <>
    <Navbar />
    <div className="page__title">
        <div className="page__title-bg">Nos services</div>
    </div>
    <div className="serv__page Container">
        <div className="serv__tit">
            <div className="serv__tit1">Conseil et accompagnement RH</div>
            <div className="serv__tit2">
                <div className="serv__tit21" style={{backgroundImage: `url(${cover1})`}}>
                    <div className="service__card__bg21">
                        <div className="service__slogan">
                            <div><span className='human'>HUMAN</span><span className='first'> FIRST</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="serv__desc">
            <div className="desc-det">
                <div>
                    <IconContext.Provider value={{ className: 'service-icons' }}>
                        <BsCheckCircleFill />
                    </IconContext.Provider>
                </div>
            <div className="desc-det-txt">Mise en place de la fonction RH </div>
            </div>
            <div className="desc-det">
                <div>
                    <IconContext.Provider value={{ className: 'service-icons' }}>
                        <BsCheckCircleFill />
                    </IconContext.Provider>
                </div>
            <div className="desc-det-txt">Etablissement de la stratégie RH  </div>
            </div>
            <div className="desc-det">
                <div>
                    <IconContext.Provider value={{ className: 'service-icons' }}>
                        <BsCheckCircleFill />
                    </IconContext.Provider>
                </div>
            <div className="desc-det-txt">Accompagnement au changement</div>
            </div>
            <div className="desc-det">
                <div>
                    <IconContext.Provider value={{ className: 'service-icons' }}>
                        <BsCheckCircleFill />
                    </IconContext.Provider>
                </div>
            <div className="desc-det-txt">Restructuration </div>
            </div>
            <div className="desc-det">
                <div>
                    <IconContext.Provider value={{ className: 'service-icons' }}>
                        <BsCheckCircleFill />
                    </IconContext.Provider>
                </div>
            <div className="desc-det-txt">Optimisation de la masse salariale  </div>
            </div>
            <div className="desc-det">
                <div>
                    <IconContext.Provider value={{ className: 'service-icons' }}>
                        <BsCheckCircleFill />
                    </IconContext.Provider>
                </div>
            <div className="desc-det-txt">Droit du travail et gestion des conflits  </div>
            </div>
            <div className="desc-det">
                <div>
                    <IconContext.Provider value={{ className: 'service-icons' }}>
                        <BsCheckCircleFill />
                    </IconContext.Provider>
                </div>
            <div className="desc-det-txt">Relation avec les partenaires sociaux  </div>
            </div>
            <div className="desc-det">
                <div>
                    <IconContext.Provider value={{ className: 'service-icons' }}>
                        <BsCheckCircleFill />
                    </IconContext.Provider>
                </div>
            <div className="desc-det-txt">Règlement intérieur  </div>
            </div>
            <div className="desc-det">
                <div>
                    <IconContext.Provider value={{ className: 'service-icons' }}>
                        <BsCheckCircleFill />
                    </IconContext.Provider>
                </div>
            <div className="desc-det-txt">Audit RH et audit social  </div>
            </div>
        </div>
    </div>
    <BackToTopButton />
    <Footer />
    </>
  )
}

export default Service1