import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'


const CarteCandidatSp = (props) => {
  

  return (
        <div className='carte__candidat'>
          <div>
            <div className='carte__candidat1'>
              <div>{props.nom}</div>
              <div>{props.niveau}</div>
              <div>{props.domain}</div>
            </div>
            <div className='carte__candidat-offre'>{props.offre}</div>
          </div>
          {props.consulter===0 && <div className='feedback'>Nouveau</div>}
          <div className='carte__btn'>
            <Link to={'/candidatSp'} state={props}> 
                <button>Afficher</button>
            </Link>
          </div>
        </div>
  )
}

export default CarteCandidatSp