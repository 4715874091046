import React, { useEffect } from 'react'
import './about.css'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'
import { BiSolidQuoteAltRight } from 'react-icons/bi'
import { IconContext } from "react-icons";
import { NavLink } from 'react-router-dom'
import hf from '../../assets/hf.gif'
import integrite from '../../assets/integrite.png'
import rigueur from '../../assets/rigueur.png'
import engagement from '../../assets/engagement.png'
import BackToTopButton from '../../BackToTopButton'



const About = () => {
    useEffect(() => {
        document.title = 'Qui sommes nous ? | RH PRO PLUS';
        
        return () => {
          document.title = 'RH PRO PLUS';
        };
      }, []);
  return (
    <>
    <Navbar/>
    <div className="page__title">
        <div className="page__title-bg">Qui sommes nous ?</div>
    </div>
    <div className="about ">
        <div className="about__content1 Container about__content11">
            <div className="valeur__title" id='valeur__title1'>
                <h1><span className='valeur__title2'>POURQUOI NOUS CHOISIR ?<br /></span>Nous mettons à votre disposition plus de 25 ans d'expérience opérationnelle dans le pilotage des Directions des Ressources Humaines des Multinationales et des Groupes Nationaux </h1>
            </div>
            <div className="banner1">
                <img src={hf} alt="human first" />
            </div>
        </div>
        <div className="about__content1 Container">
            <div className="vision__container">
            <div className="vision__title">
                <h1 className='vision__title2'>NOTRE VISION</h1>
            </div>
            <div className="vision__title">
            <h1>Être la société qui comprend et satisfait le mieux les besoins et les services en lien avec le capital humain.</h1>
            </div>
            </div>
        </div>
        <div className="about__content1 Container">
            <div className="vision__container">
            <div className="vision__title">
                <h1 className='vision__title2'>NOTRE MISSION</h1>
            </div>
            <div className="vision__title">
            <h1>Valoriser le capital humain et diffuser les bonnes pratiques, et ce pour mériter toujours le privilège d'être le partenaire de référence dans notre métier.</h1>
            </div>
            </div>
        </div>
        <div className="about__content2 Container">
            <div className="vision__title"><h1><span className='vision__title2'>NOS VALEURS</span></h1></div>
            <div className="valeur_cont">
                <div className='valeur'>
                    <div className="val__img"><img src={integrite} alt="Intégrité" /></div>
                    <div className="val__name"><h1>Intégrité</h1></div>
                </div>
                <div className='valeur'>
                    <div className="val__img"><img src={engagement} alt="Engagement" /></div>
                    <div className="val__name"><h1>Engagement</h1></div>
                </div>
                <div className='valeur'>
                    <div className="val__img"><img src={rigueur} alt="Rigueur" /></div>
                    <div className="val__name"><h1>Rigueur</h1></div>
                </div>
            </div>

        </div>
        <div className="Container">
        <div className="about__content3">
            <div className="about__content3-desc "><h1>Vous avez besoin d'un conseil et/ou d'accompagnement personnalisé des experts RH?</h1></div>
            <div className="about__content3-btn">
                <NavLink to='/Contact'>
                    <button>Discutons</button>
                </NavLink>
            </div>
        </div>
        </div>
    </div>
    <BackToTopButton />
    <Footer/>
    </>
  )
}

export default About