import React from 'react'
import './footer.css'
import logorhp from '../../assets/logorhp.svg'
import { FaFacebook ,FaLinkedin,FaTwitter, FaInstagram} from 'react-icons/fa'
import { IconContext } from "react-icons";
import MapFooter from './MapFooter';
import { NavLink } from 'react-router-dom'
import { FiMapPin } from 'react-icons/fi';

const Footer = () => {
  return (
    <footer className='Container'>
    <div className="slogan">
      <div><span className='human'>HUMAN</span><span className='first'> FIRST</span></div>
    </div>
    <div className="footer__content">
      <div className="footer__content__left">
        <div className='footer__log'><a href="/"><img src={logorhp} alt="logo RH PRO PLUS"  /></a></div>
        <div className='footer__discutons title'><h2>Discutons</h2></div>
        <div><p className='footer__content__left-text ft-text'>+212 5 37 73 87 79 <br />contact@rhpro-plus.com</p></div>
        <div>
          <NavLink to='/Contact'>
          <button className='footer__btn'>Contacter nous</button>
          </NavLink>
        </div>
      </div>
      <div className="footer__content__right">
        <div className="footer__links">
          <div className="footer__nav-links">
            <div className="footer__nav-links__title title"><h2>Découvrez</h2></div>
            <div className="links ft-text">
              <ul>
                <li><a href="/">Accueil</a></li>
                <li><a href="/Qui_sommes_nous">Qui sommes nous ?</a></li>
                <li><a href="/#services">Nos services</a></li>
                <li><a href="/Offres_d'emplois">Offres d'emplois</a></li>
                <li><a href="/Recrutement">Recrutement</a></li>
              </ul>
            </div>

          </div>
          <div className="footer__socials-links">
            <div className="footer__socials-links__title title"><h2>Suivez-nous</h2></div>
            <IconContext.Provider value={{ className: 'footer-icons' }}>
              <div className="contact__icons">
                <a href="https://www.linkedin.com/company/rh-pro-plus/" target='_blank'><FaLinkedin/></a>
                <a href="https://www.facebook.com/RH.PRO.PLUS/" target='_blank'><FaFacebook/></a>
                <a href="https://twitter.com/rhproplus1" target='_blank'><FaTwitter/></a>
                <a href="https://www.instagram.com/rhproplus/" target='_blank'><FaInstagram/></a>
              </div>
            </IconContext.Provider>
          </div>
        </div>
        <div className="footer__localisation">
          <div className="locatisation"> 
            <div className="localisation__title title"><h2>Localisation</h2></div>
            <div className="locatisation__text text ft-text"><p>Appartement 2 , immeuble C , résidence la liberté , rue Mali , Diour Jamaa , Rabat .</p></div>
            <div>
            <NavLink to='https://maps.app.goo.gl/BeXFaqL45vbxyU2M7' target='_blank' rel='noopener noreferrer'>
              <button className='footer__btn footer__btnj'>Nous rejoindre<FiMapPin/></button>
            </NavLink>
            </div>
          </div>
          <div className="footer__map">

            <MapFooter/>

          </div>
        </div>
      </div>
    </div>
    <div className="copy">
    <span className="footer__copy text ft-text">&#169;2023 - 2024 RH PRO PLUS. All Rights Reserved.</span>
    </div>
    </footer>
  )
}

export default Footer