import React, { useRef, useState, useEffect } from 'react'
import axios from 'axios';
import './recrutement.css'
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import 'react-phone-input-2/lib/style.css'; 
import PhoneInput from 'react-phone-input-2';
import emailjs from '@emailjs/browser';
import { NavLink } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BackToTopButton from '../../BackToTopButton'


const Recrutement = () => {

  const showToast = () => {
    toast.success('Votre candidature est soumis avec succés!', {
      position: 'top-left',
      autoClose: 1500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };


  

    const [nom,setNom]=useState();
    const [genre,setGenre]=useState();
    const [email,setEmail]=useState();
    const [phoneNumber,setPhoneNumber]=useState();
    const [niveau,setNiveau]=useState();
    const [domain,setDomain]=useState();
    const [experience,setExperience]=useState();
    const [salaire,setSalaire]=useState();
    const [pretentions,setPretentions]=useState();
    const [preavis,setPreavis]=useState();
    const [offre,setOffre]=useState()
    const [file, setFile] = useState(null);

    const form = useRef();

  const sendEmail = () => {
    

    emailjs.sendForm('service_recrutement-rh', 'template_bo7qsz6', form.current, 'Oq9flZ8N7YEbC3Oy2')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      
  };
   
    const handlePhoneChange = (value, country) => {
        setPhoneNumber(value);
    };
      const handleFileChange = (e) => {
        setFile(e.target.files[0]);
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('nom', nom);
        formData.append('genre', genre);
        formData.append('email', email);
        formData.append('phoneNumber', phoneNumber);
        formData.append('niveau', niveau);
        formData.append('domain', domain);
        formData.append('experience', experience);
        formData.append('salaire', salaire);
        formData.append('pretentions', pretentions);
        formData.append('preavis', preavis);
        formData.append('offre', offre);
        formData.append('file', file);
        
        const response = await fetch('https://rhpro-plus.com/phpServer/candidature.php', {
            method: 'POST',
            body: formData,
        });

        showToast();

        setNom('');
        setGenre('');
        setEmail('');
        setPhoneNumber('+212');
        setNiveau('');
        setDomain('');
        setExperience('');
        setSalaire('');
        setPretentions('');
        setPreavis('');
        setOffre('')
        setFile(null);

        /*const result = await response.json();
        console.log(result);*/
        /*try {
            await axios.post('http://localhost:3000/phpServer/candidature.php', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });
            console.log(file);
            setNom('');
            setEmail('');
            setPhoneNumber('+212');
            setNiveau('');
            setDomain('');
            setExperience('');
            setSalaire('');
            setPretentions('');
            setPreavis('');
            setOffre('')
            setFile(null);

            alert('La candidature a été soumise avec succès !');
            //sendEmail();
          } catch (error) {
            console.error(error);
            alert('La candidature a échoué. Veuillez réessayer plus tard.');
          }*/


        
        };

        const [data, setData] = useState([]);
        const [enCours, setEnCours] = useState('0');

      useEffect(() => {
      // Function to fetch data from the backend
      const fetchData = async () => {
        try {
          const response = await fetch('https://rhpro-plus.com/phpServer/getOffers.php'); // Replace with your API endpoint URL
          const data = await response.json();
          setData(data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchData();
    }, []);

    const OffreEnCours = enCours
      ? data.filter((item) => item.etat === enCours)
      : data;
    


  return (
    <>
    <Navbar/>
    <div className="page__title">
        <div className="page__title-bg">Recrutement</div>
    </div>
    <div className="Container">
        <div className="page__title2">
            <h1>Faites-nous parvenir <br />votre candidature</h1>
        </div>
        <div className="recrutement__info">
            <form ref={form} onSubmit={handleSubmit} name="form">
                <div className="info__title"><h3>Nom complet</h3></div>
                <div>
                <input type='text' name='nom' id='nom' placeholder='Votre nom complet' className='info__input'
                value={nom} onChange={(e) => {setNom(e.target.value)}} />
                </div>
                <div className="info__title"><h3>Genre</h3></div>
                <div>
                <select className='info__input' name="genre" id="genre" value={genre} onChange={(e) => {setGenre(e.target.value)}} required>
                      <option value="" hidden>Votre genre</option>
                      <option value="Homme">Homme</option>
                      <option value="Femme">Femme</option>
                </select>
                </div>
                <div  className="info__title"><h3>Adresse mail</h3></div>
                <div>
                <input type='email' name='email' id='email' placeholder='Votre Adresse mail' className='info__input'
                value={email} onChange={(e) => {setEmail(e.target.value)}} required/>
                </div>
                <div className="info__title"><h3>Numéro téléphone</h3></div>
                <div> 
                <PhoneInput country={'ma'} name='phone' inputClass="phone" style={{ width: '100%' }} value={phoneNumber} onChange={handlePhoneChange} />
                </div>
                <div className="info__title"><h3>Niveau d’étude</h3></div>
                <div>
                <select className='info__input' name="niveau" id="niveau" value={niveau} onChange={(e) => {setNiveau(e.target.value)}} required>
                      <option value="" hidden>Votre Niveau d’étude</option>
                      <option value="Baccalaureat">Baccalaureat</option>
                      <option value="BAC+2">BAC+2</option>
                      <option value="BAC+3">BAC+3</option>
                      <option value="BAC+4">BAC+4</option>
                      <option value="BAC+5">BAC+5</option>
                </select>
                </div>
                <div className="info__title"><h3>Domaine d’études</h3></div>
                <div>
                <input type='text' placeholder='Votre Domaine d’études' className='info__input'
                value={domain} name='domain' onChange={(e) => {setDomain(e.target.value)}} required/>
                </div>
                <div className="info__title"><h3>Nombre d’année d’expérience en lien avec le poste</h3></div>
                <div>
                <input type='text' placeholder='Nombre d’année d’expérience' className='info__input'
                value={experience} onChange={(e) => {setExperience(e.target.value)}} required/>
                </div>
                <div className="info__title"><h3>Salaire actuel / Dernier salaire</h3></div>
                <div>
                <input type='text' placeholder='Votre Salaire actuel / Dernier salaire' className='info__input'
                value={salaire} onChange={(e) => {setSalaire(e.target.value)}} required/>
                </div>
                <div className="info__title"><h3>Prétentions salariales</h3></div>
                <div>
                <input type='text' placeholder='Prétentions salariales' className='info__input'
                value={pretentions} onChange={(e) => {setPretentions(e.target.value)}} required/>
                </div>
                <div className="info__title"><h3>Préavis</h3></div>
                <div>
                <input type='text' placeholder='Préavis' className='info__input'
                value={preavis} onChange={(e) => {setPreavis(e.target.value)}} required/>
                </div>
                <div className="info__title"><h3>Offre souhaitée</h3></div>
                <div>
                <select className='info__input' name="offre" id="offre" value={offre} onChange={(e) => {setOffre(e.target.value)}} required>
                <option value="" hidden>Offre souhaitée</option>
                {OffreEnCours.map((offers)=>{
                  return(
                    <option  value={offers.titre}>{offers.titre}</option>
                  )
                })
                }
                </select>
                </div>
                <div className="info__title"><h3>Resume / CV</h3></div>
                <div class="file-input-container">
                    <input type="file" id="file-input" class="file-input" accept=".pdf" onChange={handleFileChange} />
                    <div><label htmlFor="file-input" class="file-input-label ">choisir un fichier</label></div>
                    {file && <div className="file-name-feedback">{file.name}</div>}
                </div>
                <div className="submit__form">
                    <button type='submit'>Envoyer</button>
                </div>
            </form>
        </div>
        <ToastContainer />
        <div className="about__content3">
            <div className="about__content3-desc"><h1>Besoin de recruter rapidement?<br />
Nous sommes là pour vous.</h1></div>
            <div className="about__content3-btn">
                <NavLink to='/Contact'>
                    <button>Discutons</button>
                </NavLink>
            </div>
        </div>
    </div>
    <BackToTopButton />
    <Footer/>
    </>
  )
}

export default Recrutement