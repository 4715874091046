import React, { useEffect, useState } from 'react'
import axios from 'axios'
import styled, { keyframes, css } from "styled-components";

const Clients = () => {
    const [images, setImages] = useState([]);

    useEffect(() => {
      fetchImages();
    }, []);
  
    const fetchImages = async () => {
      try {
        const response = await axios.get('https://rhpro-plus.com/phpServer/partenaire.php');
        setImages(response.data);
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };
    
      return (
        <AppContainer>
          <Wrapper>
            <Marquee>
              <MarqueeGroup>
                {images.map((image) => (
                  <ImageGroup>
                    <Image  src={`data:image/jpeg;base64,${image.image}`} alt={image.imageName} />
                  </ImageGroup>
                ))}
              </MarqueeGroup>
              <MarqueeGroup>
                {images.map((image) => (
                  <ImageGroup>
                    <Image  src={`data:image/jpeg;base64,${image.image}`} alt={image.imageName} />
                  </ImageGroup>
                ))}
              </MarqueeGroup>
            </Marquee>
          </Wrapper>
        </AppContainer>
      );
    }
    
    export default Clients;
    
    const AppContainer = styled.div`
      width: 100%;
      color: #000000;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    `;
    
    const Wrapper = styled.div`
      width: 100%;
      height: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    `;
    
    const Marquee = styled.div`
      display: flex;
      width: 100%;
      overflow: hidden;
      user-select: none;
    
      mask-image: linear-gradient(
        to right,
        hsl(0 0% 0% / 0),
        hsl(0 0% 0% / 1) 10%,
        hsl(0 0% 0% / 1) 90%,
        hsl(0 0% 0% / 0)
      );
    `;
    
    const scrollX = keyframes`
      from {
        left: translateX(0);
      }
      to {
        transform: translateX(-100%);
      }
    `;
    
    const common = css`
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: space-around;
      white-space: nowrap;
      animation: ${scrollX} 50s linear infinite;
    `;
    
    const MarqueeGroup = styled.div`
      ${common}
    `;
    const MarqueeGroup2 = styled.div`
      ${common}
      animation-direction: reverse;
      animation-delay: -3s;
    `;
    
    const ImageGroup = styled.div`
      display: grid;
      place-items: center;
      width:100% ;
      padding: calc(clamp(10rem, 1rem + 30vmin, 30rem) / 10);
    `;
    
    const Image = styled.img`
      object-fit: contain;
      width: 230px;
      height: 125px;
      /* border: 1px solid black; */
      border-radius: 0.5rem;
      aspect-ratio: 16/9;
      padding: 5px 20px;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    `;