import React from 'react'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'
import './services.css'
import cover7 from '../../assets/sevicesBanner/cover7.jpg'
import { BsCheckCircleFill } from 'react-icons/bs';
import { IconContext } from "react-icons";
import BackToTopButton from '../../BackToTopButton'

const Service6 = () => {
  return (
    <>
    <Navbar />
    <div className="page__title">
        <div className="page__title-bg">Nos services</div>
    </div>
    <div className="serv__page Container">
        <div className="serv__tit">
            <div className="serv__tit1">Recrutement</div>
            <div className="serv__tit2">
                <div className="serv__tit21" style={{backgroundImage: `url(${cover7})`}}>
                    <div className="service__card__bg21">
                        <div className="service__slogan">
                            <div><span className='human'>HUMAN</span><span className='first'> FIRST</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="serv__desc">
            <h1 className="desc-det-txth1">Recrutement par voie normale</h1>
            <p className="desc-det-txt">Nous vous offrons un accompagnement sur l’intégralité du processus de recrutement y compris pendant la période d’essai.</p>
            <p className="desc-det-txt">Par ailleurs, nous nous engageons à proposer d’autres candidats si la personne choisie ne donnerait pas satisfaction ou si elle quitterait votre organisation pendant la période d’essai.</p>
            <p className="desc-det-txt">Notre atout c’est notre capacité de présenter, dans les meilleurs délais aux clients, des candidats répondant aux exigences du poste, et ce en tenant compte de notre réseau relationnel et de notre expertise</p>
            <h2 className="desc-det-txth2">Notre méthodologie de travail :</h2>
            <div className="desc-det">
                <div>
                    <IconContext.Provider value={{ className: 'service-icons' }}>
                        <BsCheckCircleFill />
                    </IconContext.Provider>
                </div>
            <div className="desc-det-txt">La validation du besoin</div>
            </div>
            <div className="desc-det">
                <div>
                    <IconContext.Provider value={{ className: 'service-icons' }}>
                        <BsCheckCircleFill />
                    </IconContext.Provider>
                </div>
            <div className="desc-det-txt">La définition du poste profil et l’analyse de la demande </div>
            </div>
            <div className="desc-det">
                <div>
                    <IconContext.Provider value={{ className: 'service-icons' }}>
                        <BsCheckCircleFill />
                    </IconContext.Provider>
                </div>
            <div className="desc-det-txt">L’établissement du contrat de la mission de recrutement </div>
            </div>
            <div className="desc-det">
                <div>
                    <IconContext.Provider value={{ className: 'service-icons' }}>
                        <BsCheckCircleFill />
                    </IconContext.Provider>
                </div>
            <div className="desc-det-txt">La recherche, l’évaluation et la présélection des candidats </div>
            </div>
            <div className="desc-det">
                <div>
                    <IconContext.Provider value={{ className: 'service-icons' }}>
                        <BsCheckCircleFill />
                    </IconContext.Provider>
                </div>
            <div className="desc-det-txt">La présentation des candidats et la validation finale par le client</div>
            </div>
            <div className="desc-det">
                <div>
                    <IconContext.Provider value={{ className: 'service-icons' }}>
                        <BsCheckCircleFill />
                    </IconContext.Provider>
                </div>
            <div className="desc-det-txt">Le suivi du candidat, de son intégration jusqu’à sa titularisation</div>
            </div>
            <h1 className="desc-det-txth1">Le Recrutement par Concours</h1>
            <p className="desc-det-txt">Nous gérons vos concours selon votre demande et vos besoins : de la définition du besoin jusqu’à la présentation des listes des candidats admis.</p>
            <p className="desc-det-txt">Pareillement, nous prenons en charge la logistique nécessaire au déroulement du concours : imprimés, salles d’examens, surveillance et sécurité.</p>
            <h2 className="desc-det-txth2">Processus du déroulement du concours :</h2>
            <div className="desc-det">
                <div>
                    <IconContext.Provider value={{ className: 'service-icons' }}>
                        <BsCheckCircleFill />
                    </IconContext.Provider>
                </div>
            <div className="desc-det-txt">Publication de l’avis de concours </div>
            </div>
            <div className="desc-det">
                <div>
                    <IconContext.Provider value={{ className: 'service-icons' }}>
                        <BsCheckCircleFill />
                    </IconContext.Provider>
                </div>
            <div className="desc-det-txt">Présélection et publication de la liste des candidats répondant aux critères requis</div>
            </div>
            <h2 className="desc-det-txth2">Concours d’admissibilité :</h2>
            <div className="desc-det">
                <div>
                    <IconContext.Provider value={{ className: 'service-icons' }}>
                        <BsCheckCircleFill />
                    </IconContext.Provider>
                </div>
            <div className="desc-det-txt">Epreuve écrite</div>
            </div>
            <div className="desc-det">
                <div>
                    <IconContext.Provider value={{ className: 'service-icons' }}>
                        <BsCheckCircleFill />
                    </IconContext.Provider>
                </div>
            <div className="desc-det-txt">Test d’assesment </div>
            </div>
            <div className="desc-det">
                <div>
                    <IconContext.Provider value={{ className: 'service-icons' }}>
                        <BsCheckCircleFill />
                    </IconContext.Provider>
                </div>
            <div className="desc-det-txt">Concours d’admission : <br />

Entretien oral. <br />

Publication des résultats définitifs (Liste des candidats admis classés  par ordre de mérite et la liste complémentaire destinée à permettre le remplacement des candidats, en cas de  désistement). <br />

Présentation du Rapport de synthèse.
</div>
            </div>
        </div>
    </div>
    <BackToTopButton />
    <Footer />
    </>
  )
}

export default Service6