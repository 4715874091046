import React, { useState, useEffect } from 'react';
import axios from 'axios';

const PdfViewer = ({ fileId }) => {
  const [loading, setLoading] = useState(true);
  const [pdfData, setPdfData] = useState(null);

  useEffect(() => {
    const fetchPdfData = async () => {
      try {
        const response = await axios.get(`http://localhost:5000/pdf/${fileId}`, {
          responseType: 'arraybuffer', // Tell Axios to treat the response as binary data
        });

        // Create a Blob from the binary data received and display it in an embedded PDF viewer
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const pdfUrl = URL.createObjectURL(blob);
        setPdfData(pdfUrl);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching PDF data:', error);
        setLoading(false);
      }
    };

    fetchPdfData();
  }, [fileId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!pdfData) {
    return <div>Error fetching PDF data.</div>;
  }

  return (
    <div>
      <embed src={pdfData} width="200px" height="200px" type="application/pdf" />
      <a download={pdfData.name} href={pdfData} target="_blank" className="btn">Download CV</a>
    </div>
  );
};

export default PdfViewer;
