import React from 'react'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'
import './services.css'
import cover4 from '../../assets/sevicesBanner/cover4.jpg'
import { BsCheckCircleFill } from 'react-icons/bs';
import { IconContext } from "react-icons";
import BackToTopButton from '../../BackToTopButton'

const Service4 = () => {
  return (
    <>
    <Navbar />
    <div className="page__title">
        <div className="page__title-bg">Nos services</div>
    </div>
    <div className="serv__page Container">
        <div className="serv__tit">
            <div className="serv__tit1">Mise à disposition du personnel</div>
            <div className="serv__tit2">
                <div className="serv__tit21" style={{backgroundImage: `url(${cover4})`}}>
                    <div className="service__card__bg21">
                        <div className="service__slogan">
                            <div><span className='human'>HUMAN</span><span className='first'> FIRST</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="serv__desc">
            <div className="desc-det">
                <div>
                    <IconContext.Provider value={{ className: 'service-icons' }}>
                        <BsCheckCircleFill />
                    </IconContext.Provider>
                </div>
            <div className="desc-det-txt">Que ce soit définitivement ou pour une période temporaire (un démarrage, congé, absence, accroissement temporaire d’activité…), nous mettons à votre disposition les profils adéquats pour chaque poste </div>
            </div>
            <div className="desc-det">
                <div>
                    <IconContext.Provider value={{ className: 'service-icons' }}>
                        <BsCheckCircleFill />
                    </IconContext.Provider>
                </div>
            <div className="desc-det-txt">Selon vos besoins et vos exigences </div>
            </div>
            <div className="desc-det">
                <div>
                    <IconContext.Provider value={{ className: 'service-icons' }}>
                        <BsCheckCircleFill />
                    </IconContext.Provider>
                </div>
            <div className="desc-det-txt">Tout en prenant en charge leur gestion administrative</div>
            </div>
            <div className="desc-det">
                <div>
                    <IconContext.Provider value={{ className: 'service-icons' }}>
                        <BsCheckCircleFill />
                    </IconContext.Provider>
                </div>
            <div className="desc-det-txt">En garantissant le respect des règles juridiques, administratives et de sécurité</div>
            </div>
            <div className="desc-det">
                <div>
                    <IconContext.Provider value={{ className: 'service-icons' }}>
                        <BsCheckCircleFill />
                    </IconContext.Provider>
                </div>
            <div className="desc-det-txt">Optimiser votre masse salariale </div>
            </div>
            <div className="desc-det">
                <div>
                    <IconContext.Provider value={{ className: 'service-icons' }}>
                        <BsCheckCircleFill />
                    </IconContext.Provider>
                </div>
            <div className="desc-det-txt">Avoir de la flexibilité dans la gestion de votre personnel </div>
            </div>
            <div className="desc-det">
                <div>
                    <IconContext.Provider value={{ className: 'service-icons' }}>
                        <BsCheckCircleFill />
                    </IconContext.Provider>
                </div>
            <div className="desc-det-txt">Alléger la gestion administrative de vos collaborateurs indirects  </div>
            </div>
        </div>
    </div>
    <BackToTopButton />
    <Footer />
    </>
  )
}

export default Service4