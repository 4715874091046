import React, { useEffect, useRef } from 'react'
import Navbar from '../navbar/Navbar';
import './contact.css'
import { FaFacebook ,FaLinkedin,FaTwitter, FaInstagram} from 'react-icons/fa'
import { IconContext } from "react-icons";
import Footer from '../footer/Footer';
import logorhp from '../../assets/logorhp.svg'
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {
    const showToast = () => {
        toast.success('Votre message a été envoyé avec succès ! Merci de nous avoir contactés.', {
          position: 'top-left',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      };
      const showToast2 = () => {
        toast.error('Une erreur s\'est produite. Veuillez réessayer.', {
            position: 'top-left',
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
      };

    useEffect(() => {
        document.title = 'Contacter nous | RH PRO PLUS';
        
        return () => {
          document.title = 'RH PRO PLUS';
        };
      }, []);

    
        const form = useRef();
      
        const sendEmail = (e) => {
          e.preventDefault();
      
          emailjs.sendForm('service_rhpro-plus', 'template_14lahnc', form.current, 'Oq9flZ8N7YEbC3Oy2')
            .then((result) => {
                showToast();
            }, (error) => {
                showToast2();
            });
            e.target.reset();
        };
  return (
    <>
    <Navbar />
    <main>
        <section className='Contact__section'>
            <div className='contact__back'>
                <div className="contact__back2">
                <div className="contact__back2-img">
                    <img src={logorhp} alt="RH PRO PLUS" />
                </div>
                <div className="contact__slogan">
                    <div><span className='human'>HUMAN</span><span className='first'> FIRST</span></div>
                </div>
                </div>
            </div>
            <div className='contact__items'>
                <div className="Contact__desc">
                    <h1>Contacter nous</h1>
                    <p>Discutons. <br />Nous sommes disponible pour vous!</p>
                    <p>Contactez-nous et il nous fera plaisir de discuter, de répondre à toutes vos questions et de vous guider vers l’atteinte de vos objectifs d’affaires. Au plaisir de travailler avec vous!</p>
                    <h1>Suivez-nous</h1>
                    <IconContext.Provider value={{ className: 'react-icons' }}>
                    <div className="contact__icons">
                        <a href="https://www.linkedin.com/company/rh-pro-plus/" target='_blank'><FaLinkedin/></a>
                        <a href="https://www.facebook.com/RH.PRO.PLUS/" target='_blank'><FaFacebook/></a>
                        <a href="https://twitter.com/rhproplus1" target='_blank'><FaTwitter/></a>
                        <a href="https://www.instagram.com/rhproplus/" target='_blank'><FaInstagram/></a>
                    </div>
                    </IconContext.Provider>
                    <h1>Écrivez-nous</h1>
                </div>
                <form ref={form} onSubmit={sendEmail} className="Contact__form">
                    <div className="Contact__form-group">
                        <div className="Contact__form-div">
                        <input type="text" className="contact__form-input" name='user__name' placeholder='Votre nom' required/>
                        </div>
                        <div className="Contact__form-div">
                        <input type="text" className="contact__form-input" name='user__sec-name' placeholder='Votre prénom' required/>
                        </div>
                    </div>
                    <div className="Contact__form-div">
                        <input type="email" className="contact__form-input" name='user__email' placeholder='Votre adresse email' required/>
                    </div>
                    <div className="Contact__form-div">
                        <input type="text" className="contact__form-input" name='user__num' placeholder='Votre numéro de téléphone' required/>
                    </div>
                    <div className="Contact__form-div">
                        <input type="text" className="contact__form-input" name='subject' placeholder='Sujet' required/>
                    </div>
                    <div className="Contact__form-div contact__form-area">
                        <textarea name="user__message" id="" cols="30" rows="10" className='contact__form-input' placeholder='Votre message .......' required></textarea>
                    </div>
                    <div className="Contact__form-div">
                        <button type='submit'  className="contact__form-button">Envoyer</button>
                    </div>
                </form>
            </div>
        </section>
    </main>
    <ToastContainer />
    <Footer/>
    </>
  )
}

export default Contact