import axios from 'axios'
import React, { useEffect, useState } from 'react'
import {useLocation} from 'react-router-dom'
import AdminSideBar from '../AdminSideBar'
import CarteCandidat from './CarteCandidat'
import ExportCSV from '../../ExportCSV'

const AdminCondidats2 = ({ setIsLoggedIn }) => {
    const [candidatsList, setCandidatsList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);


    const location=useLocation();
    const titre=location.state;

  
    axios.defaults.withCredentials = true;
  
    const logout = () => {
      // Clear localStorage and set isLoggedIn to false
      localStorage.removeItem('authToken');
      localStorage.removeItem('expiration');
      localStorage.removeItem('username');
      setIsLoggedIn(false);
  };






  const fetchPdfUrl = async (base64Data) => {
    try {
      // Assuming base64Data is a base64-encoded PDF
      const fileBlob = new Blob([Uint8Array.from(atob(base64Data), c => c.charCodeAt(0))], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(fileBlob);
      return pdfUrl;
    } catch (error) {
      console.error('Error creating PDF URL:', error.message);
      throw error;
    }
  };;

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`https://rhpro-plus.com/phpServer/getcandidatsAll.php?offre=${titre}`);
      const data = response.data;
  
      // Update file_data to pdf_url in each item
      const updatedData = await Promise.all(
        data.map(async (item) => {
          if (item.file_data) {
            const pdfUrl = await fetchPdfUrl(item.file_data);
            // Create a copy of the item without the file_data property
            const updatedItem = { ...item, pdf_url: pdfUrl };
            delete updatedItem.file_data; // Delete the file_data property
            return updatedItem;
          }
          return item;
        })
      );
  
      setCandidatsList(updatedData);
    } catch (error) {
      console.error('Error fetching data:', error.message);
    }
    finally {
      setIsLoading(false);
    }
  };
  
  useEffect(() => {
    // Fetch data when the component mounts
    fetchData();
  }, [titre]);

  return (
    <>
        <div className='admin__home-cont'>
        <AdminSideBar/>
        <main className="admin__home">
            <div className="admin__nav">
                <button className='deconnexion' onClick={logout}>Déconnexion</button>
            </div>
            <div className='export'><ExportCSV csvData={candidatsList} fileName={titre + '.csv'} /></div>
            <div className="admin__home-content">
                <div className="candidats__container">
                    <div className="candidats">
                    {isLoading ? (
                      <p>Chargement en cours...</p>
                    ) : (
                      candidatsList.length > 0 ? (
                        candidatsList.map((item, index) => {
                          return <CarteCandidat {...item} />;
                        })
                      ) : (
                        <p className='cloture'>Aucun candidat à afficher.</p>
                      )
                    )}
                </div>
                </div>
            </div>
        </main>
        </div>
    </>
  )
}

export default AdminCondidats2