import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import './login.css'
import logorhp2 from '../../assets/logorhp2.svg'

const ResetPassword = () => {

    const [email, setEmail] = useState('');
    const [codeverification, setCodeverification] = useState('');
    const [newpassword, setNewpassword] = useState('');


    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
    
        // Append the data to the FormData object
        formData.append('email', email);
        formData.append('verificationCode', codeverification);
        formData.append('newPassword', newpassword);

        // Envoyez ces données au backend pour vérification et mise à jour du mot de passe
        axios.post('https://rhpro-plus.com/phpServer/reset_password.php', formData)
        .then(response => {
            // Traitez la réponse du backend
            console.log(response.data);
            alert("Votre mot de passe a été mis à jour avec succès.");
            window.location.href = '/login';
        })
        .catch(error => {
            // Gérez les erreurs
            console.error(error);
        });
    };
    
    

  return (
    <div className='login '>
        <div className="login__logo">
                <img src={logorhp2} alt="RH PRO PLUS"  />
        </div>
      <div className='login__cont'>
        <form onSubmit={handleSubmit}>
            <div className='login__div'>
                <h3 className='label'>Email</h3>
                <input type='email' placeholder='Entrer votre email' name='email' autoComplete='off' value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className='login__input' />
            </div>
            <div className='login__div'>
                <h3 className='label'>Code de Verification</h3>
                <input type='text' placeholder='Entrer le code de verification' name='codeverification' autoComplete='off' value={codeverification}
                    onChange={(e) => setCodeverification(e.target.value)}
                    className='login__input' />
            </div>
            <div className='login__div '>
                <h3 className='label'>Nouveau mot de pass</h3>
                <input type='password' placeholder='Enter le nouveau mot de passe' name='newpassword' value={newpassword}
                    onChange={(e) => setNewpassword(e.target.value)}
                    className='login__input' />
            </div>
            <button type='submit' className='connexion__btn'>Changer le mot de passe</button>
        </form>
      </div>
      <div className='change2'>Le code de vérification a été envoyé par e-mail !</div>
    </div>
  )
}

export default ResetPassword;