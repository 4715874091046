import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './offres.css'
import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import BackToTopButton from '../../BackToTopButton'

const Offres = () => {
  useEffect(() => {
    document.title = 'Nos offres d’emplois | RH PRO PLUS';
    
    return () => {
      document.title = 'RH PRO PLUS';
    };
  }, []);
  const navigate = useNavigate;
  const VilleMaroc = [
    "Casablanca",
    "Rabat",
    "Fès",
    "Marrakech",
    "Tanger",
    "Agadir",
    "Meknès",
    "Oujda",
    "Kenitra",
    "Tétouan",
    "Salé",
    "Nador",
    "Mohammedia",
    "Taza",
    "Khémisset",
    "Béni Mellal",
    "Essaouira",
    "El Jadida",
    "Tétouan",
    "Safi",
    "Ouarzazate",
    "El Kelaa des Sraghna",
    "Settat",
    "Berrechid",
    "Al Hoceima",
    "Khouribga",
    "Inezgane",
    "Skhirat",
    "Guelmim",
    "Larache",
    "Khenifra",
    "Berkane",
    "Taourirt",
    "Tiznit",
    "Témara",
    "Taounate",
    "Sidi Slimane",
    "Sidi Kacem",
    "Souk El Arbaa",
    "Taourirt",
    "Sidi Qacem",
    "Youssoufia",
    "Safi",
    "Tan-Tan",
    "Taroudant",
    "Tétouan",
    "Ouazzane",
    "Midelt",
    "Ksar El Kebir",
    "M'diq",
    "Fquih Ben Salah",
    "Azrou",
    "Fnideq",
    "Dakhla",
    "Sefrou",
    "Bouznika",
    "Ben Guerir",
    "Bouskoura",
    "Martil",
    "Tinghir",
    "Sidi Yahia El Gharb",
    "Zagora",
  ];
    const [data, setData] = useState([]);

    useEffect(() => {
      // Function to fetch data from the backend
      const fetchData = async () => {
        try {
          const response = await fetch('https://rhpro-plus.com/phpServer/getOffers.php'); // Replace with your API endpoint URL
          const data = await response.json();
          setData(data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  
      fetchData();
    }, []);


    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedCategory2, setSelectedCategory2] = useState('');
    const [enCours, setEnCours] = useState('0');
    const [cloture, setCloture] = useState('1');

    // Function to handle the select change event
    const handleSelectChange = (event) => {
      setSelectedCategory(event.target.value);
    };
    const handleSelectChange2 = (event) => {
      setSelectedCategory2(event.target.value);
    };
  
    // Filter the data based on the selected category
    const filteredData = selectedCategory
      ? data.filter((item) => item.type_contract === selectedCategory)
      : data;
    
    const filteredData2 = selectedCategory2
      ? filteredData.filter((item) => item.lieu === selectedCategory2)
      : filteredData;

    const OffreEnCours = enCours
      ? filteredData2.filter((item) => item.etat === enCours)
      : filteredData2;

    const OffreCloture = cloture
      ? filteredData2.filter((item) => item.etat === cloture)
      : filteredData2;


  return (
    <>
    <Navbar/>
    <div className="page__title">
        <div className="page__title-bg">Nos offres d’emploi</div>
    </div>
    <div className='Container empl_title'>Si vous êtes à la recherche d’un emploi, nous avons certainement la solution pour vous. Dépêchez-vous de postuler pour votre emploi de rêve!</div>
    <div className="filter__comp Container">
      <div className="filter__comp1">
      <select value={selectedCategory} onChange={handleSelectChange}>
        <option value="">Tous les types</option>
        <option value="CDI">CDI</option>
        <option value="CDD">CDD</option>
        <option value="Contrat de projet">Contrat de projet</option>
        <option value="Stage pré embauche">Stage pré embauche</option>
        <option value="Stage">Stage</option>
      </select>
      </div>
      <div className="filter__comp1">
      <select value={selectedCategory2} onChange={handleSelectChange2}>
        <option value="">Tous les lieux</option>
        {VilleMaroc.map((ville)=>{
          return(
            <option  value={ville}>{ville}</option>
          )
        })
        }
      </select>
      </div>
    </div>
    <div className="barre__recherche-cont Container grid">
        {OffreEnCours.map((item,index)=>{
          const dateObject = new Date(item.date);
          const formattedDate = dateObject.toLocaleDateString("en-GB");
                    return(
                        <div className="offres__card" key={item.id}>
                            <div className="titre__offre"><h4>{item.titre}</h4></div>
                            <div className="offres__desc"><p>Contrat : {item.type_contract}</p></div>
                            <div className="offres__desc"><p>Basé a : {item.lieu}</p></div>
                            <div className="offre__date"><p>Publié le : {formattedDate}</p></div>
                            <div>
                              <Link to={`/Offre_d'emploi/${item.reference}`} className="offres__det" > 
                                <button>Consulter</button>
                              </Link>
                            </div>
                        </div>
                    )
                })}
        {OffreCloture.map((item,index)=>{
          const dateObject = new Date(item.date);
          const formattedDate = dateObject.toLocaleDateString("en-GB");
          const handleClick = () => {
            localStorage.setItem('offreDetails', JSON.stringify(item));
            navigate("/Offre_d'emploi");
          };
                    return(
                        <div className="offres__card" key={item.id}>
                            <div className="status__cloture">Offre Cloturé</div>
                            <div className="titre__offre"><h4>{item.titre}</h4></div>
                            <div className="offres__desc"><p>Contrat : {item.type_contract}</p></div>
                            <div className="offres__desc"><p>Basé a : {item.lieu}</p></div>
                            <div className="offre__date"><p>Publié le : {formattedDate}</p></div>
                            <div>
                              <Link to="#" className="offres__det" onClick={handleClick}> 
                                <button>Consulter</button>
                              </Link>
                            </div>
                        </div>
                    )
                })}
    </div>
    <BackToTopButton />
    <Footer/>
    </>
  )
}

export default Offres