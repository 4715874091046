import React from 'react'
import Navbar from '../navbar/Navbar'
import Footer from '../footer/Footer'
import './services.css'
import cover3 from '../../assets/sevicesBanner/cover3.jpg'
import { BsCheckCircleFill } from 'react-icons/bs';
import { IconContext } from "react-icons";
import BackToTopButton from '../../BackToTopButton'

const Service3 = () => {
  return (
    <>
    <Navbar />
    <div className="page__title">
        <div className="page__title-bg">Nos services</div>
    </div>
    <div className="serv__page Container">
        <div className="serv__tit">
            <div className="serv__tit1">Formation et amélioration de l’employabilité</div>
            <div className="serv__tit2">
                <div className="serv__tit21" style={{backgroundImage: `url(${cover3})`}}>
                    <div className="service__card__bg21">
                        <div className="service__slogan">
                            <div><span className='human'>HUMAN</span><span className='first'> FIRST</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="serv__desc">
            <div className="desc-det">
                <div>
                    <IconContext.Provider value={{ className: 'service-icons' }}>
                        <BsCheckCircleFill />
                    </IconContext.Provider>
                </div>
            <div className="desc-det-txt">RH PRO PLUS vous accompagne vers une performance durable, en développant votre

richesse la plus essentielle : l’HUMAIN.</div>
            </div>
            <div className="desc-det">
                <div>
                    <IconContext.Provider value={{ className: 'service-icons' }}>
                        <BsCheckCircleFill />
                    </IconContext.Provider>
                </div>
            <div className="desc-det-txt">Notre vision de la formation est d’aligner les compétences de vos collaborateurs avec la stratégie de votre organisation. </div>
            </div>
            <div className="desc-det">
                <div>
                    <IconContext.Provider value={{ className: 'service-icons' }}>
                        <BsCheckCircleFill />
                    </IconContext.Provider>
                </div>
            <div className="desc-det-txt"> Nous accompagnons et assistons les entreprises pour atteindre leurs objectifs de formation et nous permettons aux participants d’enrichir leurs compétences. </div>
            </div>
            <div className="desc-det">
                <div>
                    <IconContext.Provider value={{ className: 'service-icons' }}>
                        <BsCheckCircleFill />
                    </IconContext.Provider>
                </div>
            <div className="desc-det-txt">Notre atout c’est que nos formateurs ont de longues expériences opérationnelles. Ce qui leurs permets de partager leur savoir-faire technico pratique en plus de la formation théorique.  </div>
            </div>
            <div className="desc-det">
                <div>
                    <IconContext.Provider value={{ className: 'service-icons' }}>
                        <BsCheckCircleFill />
                    </IconContext.Provider>
                </div>
            <div className="desc-det-txt">Nous tenons compte de la culture et de la particularité de chaque Organisation lors de la formation intra-entreprise. Pour cela, nous préparons des modules sur mesure.</div>
            </div>
            <div className="desc-det">
                <div>
                    <IconContext.Provider value={{ className: 'service-icons' }}>
                        <BsCheckCircleFill />
                    </IconContext.Provider>
                </div>
            <div className="desc-det-txt">RH PRO PLUS recourt à des partenaires experts dans leur métier, qui ont fait preuves d’une grande capacité de transférer leurs savoir et savoir-faire avec efficacité.</div>
            </div>
        </div>
    </div>
    <BackToTopButton />
    <Footer />
    </>
  )
}

export default Service3