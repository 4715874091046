import React, { useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import './login.css'
import logorhp2 from '../../assets/logorhp2.svg'

const Login = ({ setIsLoggedIn }) => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [status, setStatus] = useState('');

    

    const handleLogin = async (event) => {
        event.preventDefault();
        const response = await fetch('https://rhpro-plus.com/phpServer/login.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username, password }),
        });

        const responseBody = await response.text();
        if (response.ok) {
            setStatus(response.data);
            // Parse the response body
            try {
                const data = JSON.parse(responseBody);
                const authToken = data.token;
                const expiration = data.expiration;
                // Save the token in localStorage or cookies
                localStorage.setItem('authToken', authToken);
                localStorage.setItem('expiration', expiration);
                localStorage.setItem('username', username);
                setIsLoggedIn(true);
                checkSessionExpiration();
            } catch (error) {
                console.error('Error parsing JSON:', error);
            }
        } else {
            setStatus("Nom d'utilisateur ou mot de passe invalide !");
        }
    };

    const checkSessionExpiration = () => {
        const expiration = localStorage.getItem('expiration');

        if (expiration) {
            const expirationTime = parseInt(expiration, 10);
            const currentTime = Math.floor(Date.now() / 1000);

            if (currentTime > expirationTime) {
                // Session has expired
                console.log('Session has expired');
                // Perform logout or redirect to login page
                logout();
            } else {
                // Session is still valid, set a timeout to check again later
                const timeUntilExpiration = expirationTime - currentTime;
                setTimeout(checkSessionExpiration, timeUntilExpiration * 1000);
            }
        }
    };
    const logout = () => {
        // Clear localStorage and set isLoggedIn to false
        localStorage.removeItem('authToken');
        localStorage.removeItem('expiration');
        localStorage.removeItem('username');
        setIsLoggedIn(false);
    };

  return (
    <div className='login '>
        <div className="login__logo">
                <img src={logorhp2} alt="RH PRO PLUS"  />
        </div>
        {status && <div className='status'>{status}</div>}
      <div className='login__cont'>
        <form onSubmit={handleLogin}>
            <div className='login__div'>
                <h3 className='label'>Nom d'utilisateur</h3>
                <input type='text' placeholder="Entrez votre nom d'utilisateur" name='username' autoComplete='off' value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    className='login__input' />
            </div>
            <div className='login__div '>
                <h3 className='label'>Mot de passe</h3>
                <input type='password' placeholder="Entrez votre mot de passe" name='password' value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className='login__input' />
            </div>
            <button type='submit' className='connexion__btn'>Connexion</button>
        </form>
      </div>
      <div className='change'><a href='/motdepass-oublie'>Mot de passe Oublie?</a></div>
    </div>
  )
}

export default Login;