import React, { useEffect, useState } from 'react'
import Navbar from './../navbar/Navbar'
import Footer from '../footer/Footer'
import BackToTopButton from '../../BackToTopButton'
import './home.css'
import {delay, motion } from 'framer-motion'
import { FaFacebook ,FaLinkedin,FaTwitter, FaInstagram} from 'react-icons/fa'
import { BiSolidQuoteAltRight } from 'react-icons/bi'
import { IconContext } from "react-icons";
import { BsCheckCircleFill } from 'react-icons/bs';
import { NavLink } from 'react-router-dom'
import Clients from './Clients'
import cover1 from '../../assets/sevicesBanner/cover1.jpg'
import cover2 from '../../assets/sevicesBanner/cover2.jpg'
import cover3 from '../../assets/sevicesBanner/cover3.jpg'
import cover4 from '../../assets/sevicesBanner/cover4.jpg'
import cover5 from '../../assets/sevicesBanner/cover5.jpg'
import cover6 from '../../assets/sevicesBanner/cover6.jpg'
import cover7 from '../../assets/sevicesBanner/cover7.jpg'
import rhp from '../../assets/rhp.mp4'
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from 'react-icons/ai';






const Home = () => {
  const [showAdditionalContent, setShowAdditionalContent] = useState(false);

  const handlePlusClick = () => {
    setShowAdditionalContent(!showAdditionalContent);
  };
  useEffect(() => {
    // Change the title when the component mounts
    document.title = 'Accueil | RH PRO PLUS ';

    // Optionally, you can also change the title when the component unmounts
    return () => {
      document.title = 'RH PRO PLUS';
    };
  }, []);

  return (
    <>
    <Navbar />
    <main>
        <section className="home__content">
            <div className="bg Container">
              <div className="bg__content">
                <div className="home__text">
                  <motion.div
                    initial={{x:-650}}
                    animate={{x:0}}
                    transition={{
                      duration:'1.2',
                      delay:'0.3'
                    }}
                  className="text1">Services-consulting RH</motion.div>
                  <motion.div
                    initial={{x:-650}}
                    animate={{x:0}}
                    transition={{
                      duration:'1',
                      delay:'0.3'
                    }}
                   className="text2">CONCENTREZ-VOUS SUR VOTRE COEUR DE MÉTIER, <span className='text21'>RH PRO PLUS </span>
  PRENDRA SOIN DE VOTRE CAPITAL HUMAIN.</motion.div>
                  <motion.div
                    initial={{x:-650}}
                    animate={{x:0}}
                    transition={{
                      duration:'1.2',
                      delay:'0.3'
                    }}
                   className="buttons">
                    <a href="#services"><button className="btn">Nos services</button></a>
                  </motion.div>
                </div>
                <IconContext.Provider value={{ className: 'homee-icons' }}>
                  <div className="home__socials">
                  <a href="https://www.linkedin.com/company/rh-pro-plus/" target='_blank'><FaLinkedin/></a>
                  <a href="https://www.facebook.com/RH.PRO.PLUS/" target='_blank'><FaFacebook/></a>
                  <a href="https://twitter.com/rhproplus1" target='_blank'><FaTwitter/></a>
                  <a href="https://www.instagram.com/rhproplus/" target='_blank'><FaInstagram/></a>
                  </div>
                </IconContext.Provider>
                
                
      
              </div>
            </div>
            <div className="scroll">
                  <div className="scroll__down">
                    <a href="#mission" className="mouse__wrapper">
                      <span className="home__scroll-name">Découvrer plus</span>
                      <span className="mouse">
                        <span className="wheel"></span>
                      </span>
                    </a>
                  </div>
            </div>
          </section>
          <section>
            <div className="mission Container" id='mission'>
              <div className="mission__text1" >
                <div className="services__section-title"><h2>NOTRE MISSION</h2></div>
                <div className="mission___tit2">
                  <div className="qoute">
                  <IconContext.Provider value={{ className: 'geull1'}}>
                    <BiSolidQuoteAltRight/>
                  </IconContext.Provider>
                  </div>
                  <div className="mission__title2">valoriser le capital humain et diffuser les bonnes pratiques, et ce pour mériter toujours le privilège d’être le partenaire de référence dans notre métier</div>
                  <div className="qoute2">
                  <IconContext.Provider value={{ className: 'geull2'}}>
                    <BiSolidQuoteAltRight/>
                  </IconContext.Provider>
                    
                  </div>
                </div>
              </div>
              
            </div>
          </section>
          <div className="services__section-title Container">
              <h2>NOS SERVICES</h2>
          </div>
          <section id='services' className="services__cont Container">
            <div className="services grid">
              <div className="service__card" >
                          <div className="service__card__cover" style={{backgroundImage: `url(${cover1})`}}>
                            <div className="service__card__bg">
                              <div className="service__slogan">
                                <div><span className='human'>HUMAN</span><span className='first'> FIRST</span></div>
                              </div>
                            </div>
                          </div>
                          <div className="service__title"><h3 >Conseil et accompagnement RH</h3></div>
                          <div className='savoir__plus'><a href="/Conseil_et_accompagnement_RH"><span>En savoir plus...</span></a></div>
              </div>
              <div className="service__card" >
                          <div className="service__card__cover" style={{backgroundImage: `url(${cover2})`}}>
                            <div className="service__card__bg">
                              <div className="service__slogan">
                                <div><span className='human'>HUMAN</span><span className='first'> FIRST</span></div>
                              </div>
                            </div>
                          </div>
                          <div className="service__title"><h3 >Mise en place et/ou mise à jour des outils RH</h3></div>
                          <div className='savoir__plus'><a href="/Mise_en_place_et/ou_mise_à_jour_des_outils_RH"><span>En savoir plus...</span></a></div>
              </div>
              <div className="service__card" >
                          <div className="service__card__cover" style={{backgroundImage: `url(${cover7})`}}>
                            <div className="service__card__bg">
                              <div className="service__slogan">
                                <div><span className='human'>HUMAN</span><span className='first'> FIRST</span></div>
                              </div>
                            </div>
                          </div>
                          <div className="service__title"><h3 >Recrutement</h3></div>
                          <div className='savoir__plus'><a href="/Service_recrutement"><span>En savoir plus...</span></a></div>
              </div>
              <div className="service__card" >
                          <div className="service__card__cover" style={{backgroundImage: `url(${cover3})`}}>
                            <div className="service__card__bg">
                              <div className="service__slogan">
                                <div><span className='human'>HUMAN</span><span className='first'> FIRST</span></div>
                              </div>
                            </div>
                          </div>
                          <div className="service__title"><h3 >Formation et amélioration de l’employabilité</h3></div>
                          <div className='savoir__plus'><a href="/Formation_et_amélioration_de_l’employabilité"><span>En savoir plus...</span></a></div>
              </div>
              <div className="service__card" >
                          <div className="service__card__cover" style={{backgroundImage: `url(${cover4})`}}>
                            <div className="service__card__bg">
                              <div className="service__slogan">
                                <div><span className='human'>HUMAN</span><span className='first'> FIRST</span></div>
                              </div>
                            </div>
                          </div>
                          <div className="service__title"><h3 >Mise à disposition du personnel</h3></div>
                          <div className='savoir__plus'><a href="/Mise_à_disposition_du_personnel"><span>En savoir plus...</span></a></div>
              </div>
              <div className="service__card" >
                          <div className="service__card__cover" style={{backgroundImage: `url(${cover5})`}}>
                            <div className="service__card__bg">
                              <div className="service__slogan">
                                <div><span className='human'>HUMAN</span><span className='first'> FIRST</span></div>
                              </div>
                            </div>
                          </div>
                          <div className="service__title"><h3 >Digitalisation des processus RH</h3></div>
                          <div className='savoir__plus'><a href="/Digitalisation_des_processus_RH"><span>En savoir plus...</span></a></div>
              </div>
              {showAdditionalContent && (
                    <div className="service__card" >
                          <div className="service__card__cover" style={{backgroundImage: `url(${cover6})`}}>
                            <div className="service__card__bg">
                              <div className="service__slogan">
                                <div><span className='human'>HUMAN</span><span className='first'> FIRST</span></div>
                              </div>
                            </div>
                          </div>
                          <div className="service__title"><h3 >Externalisation</h3></div>
                          <div className='savoir__plus'><a href="/Externalisation"><span>En savoir plus...</span></a></div>
                    </div>
              )}
            </div>
          </section>
          <section className='plus_service Container'>
          <div onClick={handlePlusClick} className="plus-symbol">
                {showAdditionalContent ? <AiOutlineMinusCircle /> : <AiOutlinePlusCircle />}
          </div>
          </section>
          <div className="services__section-title Container">
              <h2>CEUX QUI NOUS FONT CONFIANCE</h2>
          </div>
          <section className="clients__container Container">
            <Clients />
          </section>
          <div className="services__section-title Container">
              <h2>MOT DU DIRECTEUR</h2>
              <div className="mot__directeur">
                <div className='dirc__mot'>
                <video width="600px" controls>
                  <source  src={rhp} type="video/mp4" />
                </video>
                </div> 
              </div>
          </div>
          <BackToTopButton />
          <Footer />
    </main>
    </>
  )
}

export default Home