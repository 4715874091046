import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './NavDropdown.css'; // Import your CSS file for styling

const NavDropdown = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleToggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  
  const handleMenuMouseLeave = () => {
    setIsDropdownOpen(false);
  };

  return (
    <div className="nav-dropdown " >
      <li className="dropdown-toggle nav__links" onClick={handleToggleDropdown}>
        <a className='nav__link '>Nos services</a>
      </li>
      {isDropdownOpen && (
        <div className="dropdown-menu" onMouseLeave={handleMenuMouseLeave}>
          
            <Link to='/Conseil_et_accompagnement_RH' ><div className="dropdown-item">Conseil et accompagnement RH</div></Link>
            <Link to='/Mise_en_place_et/ou_mise_à_jour_des_outils_RH' ><div className="dropdown-item">Mise en place et/ou mise à jour des outils RH</div></Link>
            <Link to='/Service_recrutement' ><div className="dropdown-item">Recrutement</div></Link>
            <Link to='/Formation_et_amélioration_de_l’employabilité' ><div className="dropdown-item">Formation et amélioration de l’employabilité</div></Link>
            <Link to='/Mise_à_disposition_du_personnel' ><div className="dropdown-item">Mise à disposition du personnel</div></Link>
            <Link to='/Digitalisation_des_processus_RH' ><div className="dropdown-item">Digitalisation des processus RH</div></Link>
            <Link to='/Externalisation' ><div className="dropdown-item">Externalisation</div></Link>
        </div>

        
      )}

    </div>
  );
};

export default NavDropdown;