import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import AdminSideBar from '../AdminSideBar'

const AdminUpdateOffre = ({ setIsLoggedIn }) => {
    const location=useLocation();
    const item=location.state;
    const [id, setId] = useState(item.id);
    const [titre, setTitre] = useState(item.titre);
    const [description, setDescription] = useState(item.description);
    const [type_contract, setType_contract] = useState(item.type_contract);
    const [lieu, setLieu] = useState(item.lieu);
    const [date, setDate] = useState(item.date);
    const [etat, setEtat] = useState(item.etat);
    const navigate = useNavigate()
  
    axios.defaults.withCredentials = true;


  
    const logout = () => {
      // Clear localStorage and set isLoggedIn to false
      localStorage.removeItem('authToken');
      localStorage.removeItem('expiration');
      localStorage.removeItem('username');
      setIsLoggedIn(false);
  };


//get offre by id 


//Modifier une offre d'emplois
const handleUpdate = async (e) => {
  e.preventDefault();
  if(titre && description && type_contract && lieu && date && etat){
    try {
      await axios.put(`https://rhpro-plus.com/phpServer/updateOffer.php`, {
        id,
        titre,
        description,
        type_contract,
        lieu,
        date,
        etat
      });
      navigate('/AdminOffers')

    }catch (error) {
      console.error(error);
    }

  }
};
const handleSelectChange = (event) => {
  setEtat(event.target.value);
};




    
    
  return (
    <>
        <div className='admin__home-cont'>
        <AdminSideBar/>
        <main className="admin__home">
            <div className="admin__nav">
                <button className='deconnexion' onClick={logout}>Déconnexion</button>
            </div>
            <div className="AddOffre">
                <form className="AddOffre-form" onSubmit={handleUpdate} >
                    <div className="off__title">Titre de l'offre :</div>
                    <div className="off__input">
                        <input type="text"  name="titre" id="titre" value={titre} onChange={(e) => {setTitre(e.target.value)}} placeholder="Titre de l'offre" required/>
                    </div>
                    <div className="off__title">Type de contrat :</div>
                    <div className="off__input">
                        <input type="text" name="type_contract" id="type_contract" value={type_contract} onChange={(e) => {setType_contract(e.target.value)}} placeholder="Type de contart" required/>
                    </div>
                    <div className="off__title">Lieu :</div>
                    <div className="off__input">
                        <input type="text" name="lieu" id="lieu" value={lieu} onChange={(e) => {setLieu(e.target.value)}} placeholder="Lieu" required/>
                    </div>
                    <div className="off__title">Date :</div>
                    <div className="off__input">
                        <input type="date" name="date" id="date" value={date} onChange={(e) => {setDate(e.target.value)}}  required/>
                    </div>
                    <div className="off__title">Description :</div>
                    <div className="off__input">
                        <textarea type="text" name="description" id="description" value={description} onChange={(e) => {setDescription(e.target.value)}} placeholder="Description" required/>
                    </div>
                    <div className="off__title">Etat de l'offre :</div>
                    <div className="off__input">
                    <select name="etat" id="etat" value={etat} onChange={handleSelectChange}>
                      <option value="" disabled>{etat}</option>
                      <option value="0">En Cours</option>
                      <option value="1">Cloturé</option>
                    </select>
                    </div>
                    <div className="submit__offre">
                        <button type='submit'>Valider</button>
                    </div>
                </form>
            </div>
        </main>
        </div>
    </>
  )
}

export default AdminUpdateOffre