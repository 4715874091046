import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import AdminSideBar from './AdminSideBar'
import { useNavigate } from 'react-router-dom';

const Home = ({ setIsLoggedIn }) => {

  const logout = () => {
    // Clear localStorage and set isLoggedIn to false
    localStorage.removeItem('authToken');
    localStorage.removeItem('expiration');
    localStorage.removeItem('username');
    setIsLoggedIn(false);
};

  return (
    <>
      
        <div className='admin__home-cont'>
        <AdminSideBar/>
        <main className="admin__home">
            <div className="admin__nav">
                <button className='deconnexion' onClick={logout}>Déconnexion</button>
            </div>

        </main>
        </div>

    </>
  )
}

export default Home