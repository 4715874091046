import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import AdminSideBar from '../AdminSideBar'


const Candidat = ({ setIsLoggedIn }) => {
    const [pdfData, setPdfData] = useState(null);

    const navigate = useNavigate()
    const location=useLocation();
    const data=location.state;
  
    axios.defaults.withCredentials = true;
  
    const logout = () => {
      // Clear localStorage and set isLoggedIn to false
      localStorage.removeItem('authToken');
      localStorage.removeItem('expiration');
      localStorage.removeItem('username');
      setIsLoggedIn(false);
  };
      useEffect(() => {
        const fetchPdfData = async (itemId) => {
          try {
              const response = await axios.get(`https://rhpro-plus.com/phpServer/getCv.php?id=${itemId}`, {
                  responseType: 'arraybuffer',
              });
      
              // Create a Blob from the binary data received
              const blob = new Blob([response.data], { type: 'application/pdf' });
      
              // Create a URL for the Blob and set it to a state variable for use in the React component
              const pdfUrl = URL.createObjectURL(blob);
              setPdfData(pdfUrl);

          } catch (error) {
              console.error('Error retrieving PDF item:', error.message);
          }
      };
    
        fetchPdfData(data.id);
      }, [data.id]);

 

  const deleteCandidat = (id) => {
    axios.delete(`https://rhpro-plus.com/phpServer/deleteCandidat.php?id=${id}`);
    navigate('/AdminCondidats')
  };
  



  return (
    <>
        <div className='admin__home-cont'>
        <AdminSideBar/>
        <main className="admin__home">
            <div className="admin__nav">
                <button className='deconnexion' onClick={logout}>Déconnexion</button>
            </div>
            <div className='candidat__content Container'>
                <div className="candidat__infos">
                    <div className="data">
                        <div className="inf__title">Nom complete :</div>
                        <div className="info__data">{data.nom}</div>
                    </div>
                    <div className="data">
                        <div className="inf__title">Adresse mail :</div>
                        <div className="info__data">{data.email}</div>
                    </div>
                    <div className="data">
                        <div className="inf__title">Numéro téléphone :</div>
                        <div className="info__data">{data.phoneNumber}</div>
                    </div>
                    <div className="data">
                        <div className="inf__title">Niveau d’étude :</div>
                        <div className="info__data">{data.niveau}</div>
                    </div>
                    <div className="data">
                        <div className="inf__title">Domaine d’études :</div>
                        <div className="info__data">{data.domain}</div>
                    </div>
                    <div className="data">
                        <div className="inf__title">Nombre d’année d’expérience en lien avec le poste :</div>
                        <div className="info__data">{data.experience}</div>
                    </div>
                    <div className="data">
                        <div className="inf__title">Salaire actuel / Dernier salaire :</div>
                        <div className="info__data">{data.salaire}</div>
                    </div>
                    <div className="data">
                        <div className="inf__title">Prétentions salariales :</div>
                        <div className="info__data">{data.pretentions}</div>
                    </div>
                    <div className="data">
                        <div className="inf__title">Préavis :</div>
                        <div className="info__data">{data.preavis}</div>
                    </div>
                    <div className="data">
                        <div className="inf__title">Offre souhaitée :</div>
                        <div className="info__data">{data.offre}</div>
                    </div>
                    <div className="data">
                    <a href={pdfData} target="_blank" className="download__cv">Download CV</a>
                    </div>
                    <div className="data">
                    <button className='del-candidat' onClick={() => deleteCandidat(data.id)}>Supprimer</button>
                    </div>
                </div>
                <div className='candidat__cv'>
                    {pdfData && <iframe title="PDF Viewer" width="100%" height="400" src={pdfData}></iframe>}
                </div>
            </div>

        </main>
        </div>
    </>
  )
}

export default Candidat