import React, { useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
import './login.css'
import logorhp2 from '../../assets/logorhp2.svg'

const Forgotpass = () => {
    
    const [email,setEmail]=useState('');
    const [status,setStatus]=useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
    
        // Append the data to the FormData object
        formData.append('email', email);
        axios.post('https://rhpro-plus.com/phpServer/updatePass.php',formData)
        .then(response => {
            setStatus(response.data);
        })
        .catch(error => {
            console.error(error);
        });
    };


  return (
    <div className='login '>
        <div className="login__logo">
                <img src={logorhp2} alt="RH PRO PLUS"  />
        </div>
        {status && <div className='status'>{status}</div>}
      <div className='login__cont'>
        <form onSubmit={handleSubmit}>
            <div className='login__div'>
                <h3 className='label'>Entrez votre email</h3>
                <input type='email' placeholder="Entrez votre email" name='email' autoComplete='off' value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className='login__input' />
            </div>
            <button type='submit' className='connexion__btn'>Confirmer</button>
        </form>
      </div>
    </div>
  )
}

export default Forgotpass;