import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, NavLink } from 'react-router-dom'
import AdminSideBar from '../AdminSideBar'
import './adminOffres.css'

const AdminOffers = ({ setIsLoggedIn }) => {
  const logout = () => {
    // Clear localStorage and set isLoggedIn to false
    localStorage.removeItem('authToken');
    localStorage.removeItem('expiration');
    localStorage.removeItem('username');
    setIsLoggedIn(false);
};

//les offres d'emplois
    const [data, setData] = useState([]);
    const fetchData = async () => {
      try {
        const response = await fetch('https://rhpro-plus.com/phpServer/getOffers.php'); // Replace with your API endpoint URL
        const data = await response.json();
        setData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    useEffect(() => {
      // Function to fetch data from the backend
      fetchData();
    }, []);

    const handleDelete = async (itemId) => {
      try {
        const response = await axios.delete('https://rhpro-plus.com/phpServer/deleteOffre.php', {
            data: { id: itemId },  // Send the ID as data in the request body
        });
  
        if (response.status === 200) {
          // Offer deleted successfully, notify the parent component
          alert('deleted succes')
          window.location.reload(true);
        } else {
          console.error('Error deleting offer:', response.data.error);
        }
      } catch (error) {
        console.error('Error deleting offer:', error);
      }
    };



  return (
    <>
        <div className='admin__home-cont'>
        <AdminSideBar/>
        <main className="admin__home">
            <div className="admin__nav">
                <button className='deconnexion' onClick={logout}>Déconnexion</button>
            </div>
            <div className="admin__add-offre">
              <NavLink to='/AddOffre'>
                <button className='deconnexion'>Ajouter une Offre</button>
              </NavLink>
            </div>
            <div className="admin__offers-cont Container grid">
              {data.map((item,index)=>{
                const dateObject = new Date(item.date);
                const formattedDate = dateObject.toLocaleDateString("en-GB");
                          return(
                              <div className="offres__card-admin" key={item.id}>
                                <div className="titre__offre"><h4>{item.titre}</h4></div>
                                <div className="offre__date">{Number(item.etat) === 1 ? <p className='cloture'>Cloturé</p> : <p className='encours'>En cours</p>}</div>
                                <div className="offres__desc"><p>{item.type_contract}</p></div>
                                <div className="offres__desc"><p>{item.lieu}</p></div>
                                <div className="offre__date"><p>{formattedDate}</p></div>
                                <div>
                                  <Link to={'/Candidature'} state={item.titre} className="ad__offers-button2"> 
                                    <button>Candidature</button>
                                  </Link>
                                </div>
                                <div className="ad__offers-button">
                                  <Link to={'/ModifierOffre'} state={item}> 
                                    <button>Modifier</button>
                                  </Link>
                                  <button onClick={() => handleDelete(item.id)}>Supprimer</button>
                                </div>
                              </div>
                          )
              })}
            </div>

        </main>
        </div>
    </>
  )
}

export default AdminOffers